<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="选择区域"
                    prop="province">
        <Area v-model="address"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="卡券类型"
                    required>
        <el-radio-group v-model="form.type"
                        @change="radioChange">
          <el-radio label="VOUCHER">品牌卡券</el-radio>
          <el-radio label="COUPON">微信代金券</el-radio>
          <el-radio label="REFUEL">加油券</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="选择关联券"
                    v-if="form.type === 'VOUCHER'"
                    prop="card_id">
        <el-select v-model="form.card_id"
                   style="width:400px"
                   placeholder="请选择关联券">
          <el-option v-for="(item, index) in coupons"
                     :key="index"
                     :label="item.card_name"
                     :value="item.id ? item.id : item.allot_id">
            <span style="float: left">{{ item.id ? item.id : item.allot_id }}-{{item.card_name}}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">剩余库存{{ item.quantity }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择关联券"
                    v-if="form.type === 'COUPON'"
                    prop="card_id">
        <el-select style="width:400px"
                   v-model="form.card_id"
                   filterable
                   remote
                   placeholder="请输入批次号搜索"
                   :remote-method="remoteMethod"
                   :loading="selectLoading">
          <el-option v-for="(item, index) in options"
                     :key="index"
                     :label="item.card_name"
                     :value="item.id ? item.id : item.allot_id">
            <span style="float: left">
              {{ item.id ? item.id : item.allot_id }}-{{item.card_name}}
            </span>
            <span style="float: right; color: #8492a6; font-size: 13px">
              {{item.coupon_stock_id}}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择关联券"
                    v-if="form.type === 'REFUEL'"
                    prop="card_id">
        <el-select v-model="form.card_id"
                   style="width:400px"
                   placeholder="请选择加油券">
          <el-option v-for="(item, index) in refuels"
                     :key="index"
                     :label="item.card_name"
                     :value="item.id">
            <span style="float: left">{{ item.card_name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">剩余库存{{ item.quantity }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="活动时间"
                    v-if="$store.state.user.role === 'ADMIN'"
                    prop="started_at">
        <el-date-picker v-model="dateTime"
                        @change="dateChange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="数量"
                    class="width300"
                    prop="quantity">
        <el-input type="text"
                  :min="1"
                  placeholder="请输入库存"
                  v-model.number="form.quantity"></el-input>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-switch v-model="form.is_open"></el-switch>
      </el-form-item>
      <el-form-item label="是否加入员工主页（此设置将公开派发，请谨慎操作）"
                    v-permission="['ADMIN']">
        <el-switch v-model="form.is_marketer_web"></el-switch>
      </el-form-item>
      <el-form-item label="是否加入网点主页（此设置将公开派发，请谨慎操作）"
                    v-permission="['ADMIN']">
        <el-switch v-model="form.is_net_web"></el-switch>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即创建</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { fetchAllotCards, setAllot } from '@/api/market'
import Area from '@/components/Area'
export default {
  name: 'SetAllot',
  data () {
    return {
      dateTime: [],
      selectLoading: false,
      address: {},
      coupons: [],
      vouchers: [],
      refuels: [],
      options: [],
      form: {
        province: '',
        city: '',
        district: '',
        net: '',
        type: 'VOUCHER',
        is_open: true,
        quantity: 1,
        card_id: '',
        allot_id: '',
        is_issue: false,
        started_at: '',
        ended_at: '',
        is_marketer_web: false,
        is_net_web: false
      },
      rules: {
        card_id: [
          { required: true, message: '请选择关联券', trigger: 'change' }
        ],
        started_at: [
          { required: true, message: '请选择活动日期', trigger: 'change' }
        ],
        quantity: [{ required: true, message: '请输入库存', trigger: 'blur' }],
        province: [
          {
            required: true,
            message: '请选择区域，至少为省份',
            trigger: 'change'
          }
        ]
      }
    }
  },
  components: {
    Area
  },
  methods: {
    dateChange (val) {
      this.form.started_at = val[0]
      this.form.ended_at = val[1]
    },
    async remoteMethod (query) {
      if (query !== '' && query.length > 4) {
        this.selectLoading = true
        const res = await fetchAllotCards({
          page: 1,
          size: 5,
          type: 'COUPON',
          coupon_stock_id: query
        })
        this.selectLoading = false
        if (res.meta.code === 0) {
          this.options = res.data.allots
        }
      } else {
        this.options = []
      }
    },
    radioChange (val) {
      this.form.card_id = ''
      this.fetchAllotCards(val)
    },
    areaChange (address) {
      const { form } = this
      form.province = address.province
      form.city = address.city
      form.district = address.district
      form.net = address.net
    },
    async fetchAllotCards (val) {
      const res = await fetchAllotCards({
        type: val
      })
      if (res.meta.code === 0) {
        if (val === 'VOUCHER') {
          this.coupons = res.data.allots
        } else if (val === 'REFUEL') {
          this.refuels = res.data.allots
        }
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const form = JSON.parse(JSON.stringify(this.form))
          if (this.$store.state.user.role !== 'ADMIN') {
            form.allot_id = form.card_id
            form.card_id = ''
          }
          const res = await setAllot(form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '创建成功',
              type: 'success'
            })
            this.$router.push('allot')
          } else {
            this.$message.error(res.meta.msg)
          }
        }
      })
    }
  },
  mounted () {
    this.fetchAllotCards('VOUCHER')
  }
}
</script>

<style lang="scss" scoped></style>
